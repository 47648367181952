import React, { Component } from 'react';
import Tesseract from 'tesseract.js';
import api from 'helpers/api';

class NZTA extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, loading: false, stage: 'intro' };

    this.startSurvey = this.startSurvey.bind(this);
    this.finishedSurvey = this.finishedSurvey.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileLoaded = this.fileLoaded.bind(this);
    this.scanImage = this.scanImage.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleResult = this.handleResult.bind(this);
    this.renderStage = this.renderStage.bind(this);
    this.submitResult = this.submitResult.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Flamingo Scooters';
  }

  startSurvey() {
    this.setState({ stage: 'survey' });
  }

  finishedSurvey() {
    const promptResult = window.confirm(`Are you sure you have submitted the survey? Please ensure the page shows "Your response has been recorded", if not you may have to scroll down and press the blue arrow button.`);
    if (promptResult === true) {
      this.setState({ stage: 'done', error: false, loading: false });
    }
  }

  onChange(e) {
    if (!e.target.files[0]) return;
    this.setState({ error: false, loading: true });
    const file = e.target.files[0];

    const imageType = /^image\//;
    if (!imageType.test(file.type)) {
      return this.setState({ error: 'Please select an image.', loading: false });
    }

    const reader = new FileReader();
    reader.onloadend = this.fileLoaded;
    reader.readAsDataURL(file);
  }

  resizeImg(base64, maxWidth, maxHeight, mode = false) {
    return new Promise((resolve, reject)=>{
      const img = document.createElement('img');
      img.src = base64;
      img.onload = () => {
        if (maxWidth > img.width && maxHeight > img.height) {
          resolve(base64);
          return;
        }
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > maxWidth) {
            height = height * (maxWidth / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = width * (maxHeight / height);
            height = maxHeight;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        resolve(canvas.toDataURL());
      }
    });
  }

  scanImage(img) {
    const allowedTerms = ['thankyoufor', 'taking', 'completethissurvey'];
    return Tesseract.recognize(img, 'eng').then(({ data: { text } }) => Promise.resolve(allowedTerms.filter((term) => text.toLowerCase().replace(/\W/g, '').includes(term)).length > 0));
  }

  fileLoaded(e) {
    return this.handleImage(e.target.result, window.screen.height);
  }

  handleImage(img, size) {
    return this.resizeImg(img, size, size)
      .then(this.scanImage)
      .then((result) => (result ? Promise.resolve(true) : this.scanImage(img)))
      .then(this.handleResult);
  }

  handleResult(foundResult) {
    if (foundResult) {
      return this.submitResult();
    } else {
      return this.setState({ error: 'Unable to verify your submission using the provided screenshot. Please reply to our original email with a copy of your screenshot!', loading: false });
    }
  }

  submitResult() {
    return api.post('/promo/nzta', { uuid: this.props.match.params.uuid })
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  handleResponse(res) {
    this.setState({ stage: 'done' });

    const data = res.data.data;
    if (data.valid) {
      this.setState({ error: false, loading: false, stage: 'complete' });
    } else {
      this.setState({ error: data.reason, loading: false });
    }
  }

  handleError() {
    return this.setState({ error: 'Network issue, please try again.', loading: false });
  }

  renderStage(stage, loading, error) {
    return (
      <div className="nzta-content-done">
        <p className="nzta-content-done-title">Survey Finished</p>
        <p className="nzta-content-done-subtitle">Thank you for your support!</p>
        <p className="nzta-content-done-text">This survey closed at 5pm 7 August 2023.</p>
        <p className="nzta-content-done-text">Have any questions? Contact us at <a href="mailto:hello@flamingo.co.nz">hello@flamingo.co.nz</a></p>
        <img class="nzta-content-wordmark" src="https://storage.googleapis.com/flamingo-static/images/facade/wordmark-pink.svg" alt="Flamingo" />
      </div>
    );
    // if (stage === 'survey') {
    //   return (
    //     <div className="nzta-content-form">
    //       <iframe className="nzta-content-form-iframe" src="https://nzta.au1.qualtrics.com/jfe/form/SV_9XjNKbpFLk1cJcW" title="NZTA E-Scooter Survey"></iframe>
    //       <div className="nzta-content-form-bar">
    //         <p className="nzta-content-form-bar-text">When the page shows "Your response has been recorded", take a screenshot of the page then tap the button below.</p>
    //         <button className="nzta-content-form-bar-button" onClick={this.finishedSurvey}>Finished Survey</button>
    //       </div>
    //     </div>
    //   );
    // }
    // if (stage === 'done') {
    //   return (
    //     <div className="nzta-content-done">
    //       <p className="nzta-content-done-title">Thank You</p>
    //       <p className="nzta-content-done-subtitle">Redeem your 20 minutes free!</p>
    //       <p className="nzta-content-done-text">Please select the screenshot of your finished survey using the button below to redeem your 20 minutes free with Flamingo.</p>
    //       {
    //         loading ? (
    //           <div className="nzta-content-done-loading">
    //             <div className="lds-heart"><div></div></div>
    //             <p className="nzta-content-done-loading-text">Please wait...</p>
    //           </div>
    //         ) : (
    //           <input className="nzta-content-done-file" type="file" accept="image/*" onChange={this.onChange} />
    //         )
    //       }
    //       {
    //         error &&
    //         <>
    //           <p className="nzta-content-done-error">{ error }</p>
    //           <button className="nzta-content-done-button" onClick={this.startSurvey}>Return to Survey</button>
    //           <p className="nzta-content-done-text">Having trouble verifying your submission? Contact us at <a href="mailto:hello@flamingo.co.nz">hello@flamingo.co.nz</a></p>
    //         </>
    //       }
    //       <img className="nzta-content-wordmark" src="https://storage.googleapis.com/flamingo-static/images/facade/wordmark-pink.svg" alt="Flamingo" />
    //     </div>
    //   );
    // }
    // if (stage === 'complete') {
    //   return (
    //     <div className="nzta-content-done">
    //       <p className="nzta-content-done-title">Thank You</p>
    //       <p className="nzta-content-done-subtitle">We appreciate your support!</p>
    //       <p className="nzta-content-done-text">20 minutes of free Flamingo credit has been applied to your account! Happy riding!</p>
    //       <p className="nzta-content-done-text">Have any questions? Contact us at <a href="mailto:hello@flamingo.co.nz">hello@flamingo.co.nz</a></p>
    //       <img class="nzta-content-wordmark" src="https://storage.googleapis.com/flamingo-static/images/facade/wordmark-pink.svg" alt="Flamingo" />
    //     </div>
    //   );
    // }
    // return (
    //   <div className="nzta-content-intro">
    //     <p className="nzta-content-intro-title">Waka Kotahi E-Scooter Renewal</p>
    //     <p className="nzta-content-intro-subtitle">Get 20 minutes free credit!</p>
    //     <p className="nzta-content-intro-text">Waka Kotahi are seeking feedback on whether to renew the e-scooter declaration which allows Flamingo to operate. The results of this survey will have a significant impact on private and shared e-scooters across all of New Zealand.</p>
    //     <p className="nzta-content-intro-text">To help gain valuable feedback, Flamingo is providing 20 minutes of free credit to anyone who completes the survey. The survey is anonymous and will only take a few minutes, simply select:</p>
    //     <p className="nzta-content-intro-correct">The e-scooter declaration should be renewed</p>
    //     <p className="nzta-content-intro-text">Then provide any additional information about how electric scooter sharing has positively changed the way you travel and/or reduced your whānau's reliance on motor vehicles.  We’d also love for you to mention how the option to ride on the footpath has made it easier for you to embrace e-scooters.</p>
    //     <p className="nzta-content-intro-text">Once submitted, take a screenshot of the final page so we can verify you have completed the survey. The 20 minutes free will then be applied to your Flamingo account for your next ride.</p>
    //     <p className="nzta-content-intro-text">Only tap the "Finish Survey" button once the page shows "Your response has been recorded."</p>
    //     <button className="nzta-content-intro-button" onClick={this.startSurvey}>Start Survey</button>
    //     <p className="nzta-content-intro-disclaimer">Only information provided in the form will be shared with Waka Kotahi. No personal information will be provided from Flamingo to Waka Kotahi as part of this survey. Responses must be submitted by 5pm on 7 August 2023. One submission per person. One 20 minute Flamingo credit per successful submission. $1 unlocking fee still applies when redeeming free minutes.</p>
    //     <img class="nzta-content-wordmark" src="https://storage.googleapis.com/flamingo-static/images/facade/wordmark-pink.svg" alt="Flamingo" />
    //   </div>
    // );
  }

  render() {
    const { stage, loading, error } = this.state;
    return (
      <div className="nzta">
        <div className="nzta-content">
          { this.renderStage(stage, loading, error) }
        </div>
      </div>
    );
  }
}

export default NZTA;

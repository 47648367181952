import React, { Component } from 'react';
import Confetti from 'react-confetti'
import Loading from 'components/common/loading';
import api from 'helpers/api';
import history from 'helpers/history';

class Birthday extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, error: false, loading: true, spinning: false, prize: {}, won: false };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.loadPromo = this.loadPromo.bind(this);
    this.claimPrize = this.claimPrize.bind(this);

    this.handleSpin = this.handleSpin.bind(this);
    this.handleWin = this.handleWin.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  // ----- Mounting -----

  componentDidMount() {
    document.title = 'Birthday | Flamingo Scooters';
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.loadPromo();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  // ----- Networking -----

  loadPromo() {
    const token = this.props.match.params.token;
    if (!token) {
      return this.setState({ loading: false, error: `You are not logged in.` });
    }

    history.push('/birthday')
    api.post('/promo/birthday', { token })
      .then((res) => this.setState({ loading: false, token, prize: res.data.data }))
      .catch(this.handleError);
  }

  claimPrize() {
    const token = this.state.token;
    api.post('/promo/birthday/win', { token })
      .then((res) => this.setState({ prize: res.data.data }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : e.toString();
    this.setState({ error, loading: false });
  }

  // ----- Handlers -----

  handleSpin() {
    const { prize, spinning } = this.state;
    if (!spinning && !prize.redeemed) {
      this.setState({ spinning: true });
      setTimeout(this.handleWin, 9000);
      this.claimPrize();
    }
  }

  handleWin() {
    this.setState({ confetti: true, won: true });
    setTimeout(this.handleDone, 10000);
  }

  handleDone() {
    this.setState({ confetti: false });
  }

  // ----- Renders -----

  render() {
    const { loading, error, width, height, confetti, spinning, prize, won } = this.state;

    // Error render
    if (error) {
      return (
        <div className="fm-birthday-error">
          <p className="fm-birthday-error-text">{ error }</p>
          <p className="fm-birthday-error-text">Try closing this window and tapping the banner in the Flamingo app again.</p>
        </div>
      );;
    }

    // Loading error
    if (loading) {
      return (
        <div className="fm-birthday">
          <div className="fm-birthday-loading">
            <Loading />
          </div>
        </div>
      );;
    }

    // Render data
    const prizes = {
      m5: '5 minutes of FREE credit',
      m10: '10 minutes of FREE credit',
      m100: '100 minutes of FREE credit',
      m1000: '1,000 minutes of FREE credit',
      u10: '10 FREE unlocks',
      c5: '$5 of FREE credit',
      c10: '$10 of FREE credit',
      g1: '1 week of Go Pass for FREE',
    };
    const prizeWording = prizes[prize.code];
    const spinnerClass = `fm-birthday-wheel-spinner` + (spinning || prize.redeemed ? ` fm-birthday-wheel-spinner-${prize.code}` : ``);

    // Main render
    return (
      <div className="fm-birthday">
        <div className="fm-birthday-content">
          <div className="fm-birthday-header">
            <div className="fm-birthday-header-banner"></div>
            <p className="fm-birthday-header-text">Flamingo turns 3 this week! As a New Zealand owned and operated company we can't thank you enough for riding with us over the last three years. To celebrate, spin the wheel and unlock a prize!</p>
          </div>
          <div className="fm-birthday-wheel" onClick={this.handleSpin}>
            <div className={spinnerClass}></div>
            <div className="fm-birthday-wheel-frame"></div>
          </div>
          <div className="fm-birthday-controls">
            {
              won ? (
                <p className="fm-birthday-controls-won"><b>Congratulations!</b> You have won { prizeWording }. This has been applied to your Flamingo account.</p>
              ) : (
                !spinning && prize.redeemed ? (
                  <p className="fm-birthday-controls-won"><b>You've already spun the wheel!</b> You won { prizeWording }. This has been applied to your Flamingo account.</p>
                ) : (
                  <button className="fm-birthday-wheel-button" onClick={this.handleSpin}>SPIN</button>
                )
              )
            }
          </div>
          <p className="fm-birthday-footer">1 spin per person. Only redeemable from 00:00 11 June 2022 to 23:59 18 June 2022. $1 unlocking fee still applies for free minute vouchers. Minute, unlock and credit vouchers are valid for 2 weeks. Birthday Go Pass is valid for 1 week. Prizes may take up to 10 minutes to be applied to your account. Flamingo reserves the right to remove prizes from accounts that break these conditions.</p>
          <p className="fm-birthday-footer">Flamingo's 3rd Birthday 2022</p>
          { confetti && <Confetti width={width} height={height} numberOfPieces={100} colors={['#d81759', '#ff4887']} confettiSource={{ x: 0, y: 0, h: 0, w: width }} /> }
        </div>
      </div>
    );
  }
}

export default Birthday;

import React, { Component } from 'react';

class QuizCard extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedAnswer: false, result: false };

    this.handleNext = this.handleNext.bind(this);
    this.selectAnswer = this.selectAnswer.bind(this);
    this.renderAnswer = this.renderAnswer.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.question.question !== this.props.question.question) {
      this.setState({ selectedAnswer: false, result: false });
    }
  }

  /* Controls */

  selectAnswer(i) {
    this.setState({ selectedAnswer: i });
    if (this.props.question.correctAnswers.length === 1) {
      this.handleNext(i);
    }
  }

  handleNext() {

    this.setState({ result: 'correct' });

    if (this.props.onNext) {
      this.props.onNext();
    }
  }

  /* Renders */

  renderAnswer(answer, i) {
    const { selectedAnswer } = this.state;
    return (
      <div className={ `fm-quiz-card-answer ${ selectedAnswer === i ? 'fm-quiz-card-answer-selected' : '' }` } key={i} onClick={() => this.selectAnswer(i)}>
        <div className="fm-quiz-card-answer-box">
          <p className="fm-quiz-card-answer-label">{ answer.label }</p>
        </div>
      </div>
    );
  }

  render() {
    const { question } = this.props;
    const { result } = this.state;
    const answersClass = `fm-quiz-card-answers ${ result ? (result === 'correct' ? 'fm-quiz-card-answers-correct' : 'fm-quiz-card-answers-incorrect') : '' }`;

    return (
      <div className="fm-quiz-card">
        <p className="fm-quiz-card-question">{ question.question }</p>
        <div className={answersClass}>
          { question.answers.map(this.renderAnswer) }
        </div>
        {
          /*
          <div className="fm-quiz-card-button">
            <button className="fm-quiz-card-next" onClick={this.handleNext}>Next</button>
          </div>
          */
        }
      </div>
    );
  }
}

export default QuizCard;

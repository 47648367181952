import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from 'helpers/history';

// import Love from 'components/love';
import Birthday from 'components/birthday';
import Flappy from 'components/flappy';
// import Download from 'components/download';
import HuntRedeemed from 'components/hunt-redeemed';
import HuntWin from 'components/hunt-win';
import HuntTooMany from 'components/hunt-too-many';
import HuntDistance from 'components/hunt-distance';
import HuntNoLocation from 'components/hunt-no-location';
import Quiz from 'components/quiz';
import CovidPass from 'components/covid-pass';
import BeeCard from 'components/bee-card';
import NZTA from 'components/nzta';
import WCC from 'components/wcc';
import Advent from 'components/advent';
import Bike from 'components/bike';
import Workplace from 'components/workplace';
import Again from 'components/again';
import Parking from 'components/parking';
import Home from 'components/home';

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/quiz/:quiz?/:question?" component={Quiz} />
          <Route path="/birthday/:token?" component={Birthday} />
          <Route path="/flappy/:token?" component={Flappy} />
          <Route path="/hunt/too-many" component={HuntTooMany} />
          <Route path="/hunt/too-many" component={HuntTooMany} />
          <Route path="/hunt/redeemed" component={HuntRedeemed} />
          <Route path="/hunt/distance" component={HuntDistance} />
          <Route path="/hunt/no-location" component={HuntNoLocation} />
          <Route path="/hunt/win/:value/:type?" component={HuntWin} />
          <Route path="/covid-pass/:uuid" component={CovidPass} />
          <Route path="/bee-card/" component={BeeCard} />
          <Route path="/nzta/:uuid" component={NZTA} />
          <Route path="/wcc/:uuid" component={WCC} />
          <Route path="/advent/:uuid" component={Advent} />
          <Route path="/bike/:uuid?" component={Bike} />
          <Route path="/again/:uuid?" component={Again} />
          <Route path="/parking/:uuid?" component={Parking} />
          <Route path="/workplace" component={Workplace} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    );
  }
}

export default App;

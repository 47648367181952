import React, { Component } from 'react';

class Workplace extends Component {
  componentDidMount() {
    document.title = 'Workplace  | Flamingo Scooters';
  }

  // ----- Renders -----

  render() {
    // Main render
    return (
      <div className="fm-bike">
        <div className="fm-bike-header">
          <img className="fm-bike-header-wordmark" alt="Flamingo" src="/assets/images/wordmark.svg" />
          <p className="fm-bike-header-title">Workplace Sessions</p>
          <img className="fm-bike-header-background" alt="Flamingo Bike" src="https://storage.googleapis.com/flamingo-static/images/promo/bike-splash.jpg" />
        </div>
        <div className="fm-bike-content">
          <p className="fm-bike-instructions">You're all set to ride with your workplace!</p>
          <p className="fm-bike-instructions">You can now close this window and continue to unlock your bike.</p>
        </div>
      </div>
    );
  }
}

export default Workplace;

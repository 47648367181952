import React, { Component } from 'react';
import Tesseract from 'tesseract.js';

class BeeCard extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, loading: false };

    this.onChange = this.onChange.bind(this);
    this.fileLoaded = this.fileLoaded.bind(this);
    this.scanImage = this.scanImage.bind(this);
    this.getWorker = this.getWorker.bind(this);
    this.createWorker = this.createWorker.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleResult = this.handleResult.bind(this);
  }

  componentDidMount() {
    document.title = 'Flamingo Scooters';
  }

  onChange(e) {
    if (!e.target.files[0]) return;
    this.setState({ error: false, loading: true });
    const file = e.target.files[0];

    const imageType = /^image\//;
    if (!imageType.test(file.type)) {
      return this.setState({ error: 'Please select an image.', loading: false });
    }

    const reader = new FileReader();
    reader.onloadend = this.fileLoaded;
    reader.readAsDataURL(file);
  }

  resizeImg(base64, maxWidth, maxHeight, mode = false) {
    console.log(maxHeight, mode);
    return new Promise((resolve, reject)=>{
      const img = document.createElement('img');
      img.src = base64;
      img.onload = () => {
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > maxWidth) {
            height = height * (maxWidth / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = width * (maxHeight / height);
            height = maxHeight;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        if (mode) {
          const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          for (let i = 0; i < imgData.data.length; i += 4) {
            if (mode === 'greyscale') {
              let count = imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2];
              let colour = 0;
              if (count > 510) {
                colour = 255;
              } else if (count > 255) {
                colour = 127.5;
              }
              imgData.data[i] = colour;
              imgData.data[i + 1] = colour;
              imgData.data[i + 2] = colour;
              imgData.data[i + 3] = 255;
            } else if (mode === 'yellow') {
              if (imgData.data[i] > 200 && imgData.data[i + 1] > 150 && imgData.data[i + 2] < 50) {
                imgData.data[i] = 255;
                imgData.data[i + 1] = 255;
                imgData.data[i + 2] = 255;
                imgData.data[i + 3] = 255;
              }
            } else if (mode === 'fullYellow') {
              if (imgData.data[i] > 150 && imgData.data[i + 1] > 100 && imgData.data[i + 2] < 50) {
                imgData.data[i] = 255;
                imgData.data[i + 1] = 255;
                imgData.data[i + 2] = 255;
                imgData.data[i + 3] = 255;
              }
            }
          }
          ctx.putImageData(imgData, 0, 0);
        }

        resolve(canvas.toDataURL());
      }
    });
  }

  scanImage(img) {
    return this.getWorker().then((worker) => worker.recognize(img)).then(({ data: { text } }) => Promise.resolve(text.toLowerCase().replace(/\W/g, '').includes('ee')));
  }

  getWorker() {
    if (this.worker) {
      return Promise.resolve(this.worker);
    }
    this.worker = this.createWorker();
    return this.worker;
  }

  createWorker() {
    return Tesseract.createWorker('eng');
  }

  fileLoaded(e) {
    return this.handleImage(e.target.result, 200);
  }

  handleImage(img, size, mode = false) {
    return this.resizeImg(img, size, size, mode)
      .then(this.scanImage)
      .then((foundBee) => {
        if (foundBee) {
          return this.handleResult(foundBee);
        } else if (!mode) {
          return this.handleImage(img, size, 'greyscale');
        } else if (mode === 'greyscale') {
          return this.handleImage(img, size, 'yellow');
        } else if (mode === 'yellow') {
          return this.handleImage(img, size, 'fullYellow');
        } else if (size === 200) {
          return this.handleImage(img, 400, false);
        } else if (size === 400) {
          return this.handleImage(img, 600, false);
        } else {
          return this.handleResult(foundBee);
        }
      });
  }

  handleResult(foundBee) {
    if (foundBee) {
      window.location = 'https://rewards.rideflamingo.com/completed';
      return;
    } else {
      return this.setState({ error: 'Unable to find a Bee Card in image. Please try again.', loading: false });
    }
  }

  render() {
    const { loading, error } = this.state;
    return (
      <div className="bee-card">
        <div className="bee-card-content">
          <p className="bee-card-title">Bee Card</p>
          <p className="bee-card-about">Earn 250 Flamingo Safety Reward Points for having a Bee Card!</p>
          <p className="bee-card-instructions">Simply take a photo of your Bee Card, then select the image with the button below to verify your card. Make sure you take a photo of the front of your Bee Card and there is a solid background behind it. </p>
          { error && <p className="bee-card-error">{ error }</p> }
          <div className="bee-card-form">
            {
              loading ? (
                <div className="bee-card-loading">
                  <div className="lds-heart"><div></div></div>
                  <p className="bee-card-loading-text">Please wait...</p>
                </div>
              ) : (
                <input type="file" accept="image/*" onChange={this.onChange} />
              )
            }
          </div>
          <p className="bee-card-disclaimer">The Bee Card must be registered under the name on your Flamingo account to be eligible. Your data will be verified on our secure servers but will not be stored anywhere. Flamingo is not affiliated with Bee Card in any way. Bee Card is property of Otago Regional Council. Visit beecard.co.nz for more information.</p>
        </div>
      </div>
    );
  }
}

export default BeeCard;

const config = {
  quizzes: {
    brisbanesaferiding: {
      title: 'Brisbane Safe Riding Quiz',
      moduleKey: 'quiz-brisbane-safety',
      photoUrl: 'https://www.queensland.com/au/en/places-to-see/destinations/brisbane/brisbane-city.thumb.800.480.png',
      introduction: [
        { type: 'heading', value: 'Test Your Brakes' },
        { type: 'paragraph', value: 'It’s very important to always test your brakes before you start riding.' },
        { type: 'heading', value: 'Wear a Helmet' },
        { type: 'paragraph', value: 'Always wear a helmet for a safe ride, it’s required by law and Queensland Police actively enforce this, $130 fine applies. With Flamingo Helmet Selfies, you can earn free riding credit by tapping the helmet icon in the bottom right-hand corner of the map after you start your ride.' },
        { type: 'heading', value: 'Ride Considerately' },
        { type: 'paragraph', value: 'Always give way to pedestrians and cars and travel at a safe speed. Pedestrians have right of way on the footpath. ' },
        { type: 'heading', value: 'Where to Ride Scooters' },
        { type: 'paragraph', value: 'You can always ride your scooter on the footpath. In the Brisbane CBD, you must not ride on the road. You can ride on local roads in Brisbane suburbs.' },
        { type: 'heading', value: 'Where to Ride Bikes' },
        { type: 'paragraph', value: 'Ride your bike on the road and in designated bike lanes. You may only ride alongside 1 other person when travelling on a road in the same direction.' },
        { type: 'heading', value: 'Never Drink and Ride' },
        { type: 'paragraph', value: 'If you wouldn’t drive, you shouldn’t ride. Do not consume alcohol prior to, or while, riding.' },
        { type: 'heading', value: 'One Per E-Vehicle' },
        { type: 'paragraph', value: 'It is strictly one person per e-vehicle, regardless of the circumstances.' },
      ],
      questions: [
        {
          question: 'Is it okay to ride on one e-vehicle with a friend just to get to another Flamingo e-vehicle?',
          correctAnswers: [1],
          type: 'radio',
          answers: [
            { label: 'Yes' },
            { label: 'No' },
          ],
        },
        {
          question: 'What’s the most important thing to do at the beginning of your ride?',
          correctAnswers: [2],
          type: 'radio',
          answers: [
            { label: 'Check how many other scooters and bikes are around' },
            { label: 'Determine your travel route' },
            { label: 'Test the brakes' },
            { label: 'Share your Flamingo referral code' },
          ],
        },
        {
          question: 'Who has the right of way on the footpath?',
          correctAnswers: [2],
          type: 'radio',
          answers: [
            { label: 'Scooter riders' },
            { label: 'Cyclists' },
            { label: 'Pedestrians' },
          ],
        },
        {
          question: 'How much will Queensland Police fine you if they catch you riding without a helmet?',
          correctAnswers: [3],
          type: 'radio',
          answers: [
            { label: '$50' },
            { label: '$90' },
            { label: '$100' },
            { label: '$130' },
          ],
        },
        {
          question: 'Can you ride a scooter on the road in Brisbane CBD?',
          correctAnswers: [1],
          type: 'radio',
          answers: [
            { label: 'Yes' },
            { label: 'No' },
          ],
        },
        {
          question: 'Can you ride a bike on the road in Brisbane CBD?',
          correctAnswers: [0],
          type: 'radio',
          answers: [
            { label: 'Yes' },
            { label: 'No' },
          ],
        },
        {
          question: 'Can you ride a scooter on the road in Brisbane suburbs?',
          correctAnswers: [0],
          type: 'radio',
          answers: [
            { label: 'Yes' },
            { label: 'No' },
          ],
        },
        {
          question: 'Can you ride a scooter or bike if you’re too drunk to drive home?',
          correctAnswers: [1],
          type: 'radio',
          answers: [
            { label: 'Yes' },
            { label: 'No' },
          ],
        },
      ],
    },
  },
};

export default config;

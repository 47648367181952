import React, { Component } from 'react';
import Tesseract from 'tesseract.js';
import api from 'helpers/api';

class WCC extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, loading: false, stage: 'intro' };

    this.startSurvey = this.startSurvey.bind(this);
    this.finishedSurvey = this.finishedSurvey.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileLoaded = this.fileLoaded.bind(this);
    this.scanImage = this.scanImage.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleResult = this.handleResult.bind(this);
    this.renderStage = this.renderStage.bind(this);
    this.submitResult = this.submitResult.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Flamingo Scooters';
  }

  startSurvey() {
    this.setState({ stage: 'survey' });
  }

  finishedSurvey() {
    const promptResult = window.confirm(`Are you sure you have submitted the survey and taken a screenshot? Please ensure the page shows "Thank you for your participation." and no more buttons, if not you may have to scroll down and press the grey arrow / paper plane button.`);
    if (promptResult === true) {
      this.setState({ stage: 'done', error: false, loading: false });
    }
  }

  onChange(e) {
    if (!e.target.files[0]) return;
    this.setState({ error: false, loading: true });
    const file = e.target.files[0];

    const imageType = /^image\//;
    if (!imageType.test(file.type)) {
      return this.setState({ error: 'Please select an image.', loading: false });
    }

    const reader = new FileReader();
    reader.onloadend = this.fileLoaded;
    reader.readAsDataURL(file);
  }

  resizeImg(base64, maxWidth, maxHeight, mode = false) {
    return new Promise((resolve, reject)=>{
      const img = document.createElement('img');
      img.src = base64;
      img.onload = () => {
        if (maxWidth > img.width && maxHeight > img.height) {
          resolve(base64);
          return;
        }
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > maxWidth) {
            height = height * (maxWidth / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = width * (maxHeight / height);
            height = maxHeight;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        resolve(canvas.toDataURL());
      }
    });
  }

  scanImage(img) {
    const allowedTerms = ['thankyouforyour', 'participation', 'sharingyour'];
    return Tesseract.recognize(img, 'eng').then(({ data: { text } }) => Promise.resolve(allowedTerms.filter((term) => text.toLowerCase().replace(/\W/g, '').includes(term)).length > 0));
  }

  fileLoaded(e) {
    return this.handleImage(e.target.result, window.screen.height);
  }

  handleImage(img, size) {
    return this.resizeImg(img, size, size)
      .then(this.scanImage)
      .then((result) => (result ? Promise.resolve(true) : this.scanImage(img)))
      .then(this.handleResult);
  }

  handleResult(foundResult) {
    if (foundResult) {
      return this.submitResult();
    } else {
      return this.setState({ error: 'Unable to verify your submission using the provided screenshot. Please contact us using the email below with a copy of your screenshot!', loading: false });
    }
  }

  submitResult() {
    return api.post('/promo/wcc', { uuid: this.props.match.params.uuid })
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  handleResponse(res) {
    this.setState({ stage: 'done' });

    const data = res.data.data;
    if (data.valid) {
      this.setState({ error: false, loading: false, stage: 'complete' });
    } else {
      this.setState({ error: data.reason, loading: false });
    }
  }

  handleError() {
    return this.setState({ error: 'Network issue, please try again.', loading: false });
  }

  renderStage(stage, loading, error) {
    // return (
    //   <div className="wcc-content-done">
    //     <p className="wcc-content-done-title">Survey Finished</p>
    //     <p className="wcc-content-done-subtitle">Thank you for your support!</p>
    //     <p className="wcc-content-done-text">This survey closed at 5pm 7 August 2023.</p>
    //     <p className="wcc-content-done-text">Have any questions? Contact us at <a href="mailto:hello@flamingo.co.nz">hello@flamingo.co.nz</a></p>
    //     <img class="wcc-content-wordmark" src="https://storage.googleapis.com/flamingo-static/images/facade/wordmark-pink.svg" alt="Flamingo" />
    //   </div>
    // );
    if (stage === 'survey') {
      return (
        <div className="wcc-content-form">
          <div className="wcc-content-form-bar">
            <p className="wcc-content-form-bar-text">Once you have finished the survey and tapped the <i className="fa fa-paper-plane"></i> button, <b>take a screenshot</b> of the page then tap the button below.</p>
            <button className="wcc-content-form-bar-button" onClick={this.finishedSurvey}>Upload & Redeem Credit</button>
          </div>
          <iframe className="wcc-content-form-iframe" src="https://ap1se.voxco.com/S2/23/Micromobility-Survey/" title="WCC Survey"></iframe>
        </div>
      );
    }
    if (stage === 'done') {
      return (
        <div className="wcc-content-done">
          <p className="wcc-content-done-title">Thank You</p>
          <p className="wcc-content-done-subtitle">Redeem your 20 minutes free!</p>
          <p className="wcc-content-done-text">Please select the screenshot of your finished survey using the button below to redeem your 20 minutes free with Flamingo.</p>
          {
            loading ? (
              <div className="wcc-content-done-loading">
                <div className="lds-heart"><div></div></div>
                <p className="wcc-content-done-loading-text">Please wait...</p>
              </div>
            ) : (
              <input className="wcc-content-done-file" type="file" accept="image/*" onChange={this.onChange} />
            )
          }
          {
            error &&
            <>
              <p className="wcc-content-done-error">{ error }</p>
              <button className="wcc-content-done-button" onClick={this.startSurvey}>Return to Survey</button>
              <p className="wcc-content-done-text">Having trouble verifying your submission? Contact us at <a href="mailto:hello@flamingo.co.nz">hello@flamingo.co.nz</a></p>
            </>
          }
          <img className="wcc-content-wordmark" src="https://storage.googleapis.com/flamingo-static/images/facade/wordmark-pink.svg" alt="Flamingo" />
        </div>
      );
    }
    if (stage === 'complete') {
      return (
        <div className="wcc-content-done">
          <p className="wcc-content-done-title">Thank You</p>
          <p className="wcc-content-done-subtitle">We appreciate your feedback!</p>
          <p className="wcc-content-done-text">20 minutes of free Flamingo credit has been applied to your account! Happy riding!</p>
          <p className="wcc-content-done-text">Have any questions? Contact us at <a href="mailto:hello@flamingo.co.nz">hello@flamingo.co.nz</a></p>
          <img class="wcc-content-wordmark" src="https://storage.googleapis.com/flamingo-static/images/facade/wordmark-pink.svg" alt="Flamingo" />
        </div>
      );
    }
    return (
      <div className="wcc-content-intro">
        <p className="wcc-content-intro-title">Wellington Micromobility Survey</p>
        <p className="wcc-content-intro-subtitle">Get 20 minutes free credit!</p>
        <p className="wcc-content-intro-text">Wellington City Council are seeking feedback on micromobility in Wellington.</p>
        <p className="wcc-content-intro-text">To help gain valuable feedback, Flamingo is providing 20 minutes of free credit to anyone who completes the survey.</p>
        <p className="wcc-content-intro-text">The survey is anonymous and will only take approximately 10 minutes to complete.</p>
        <p className="wcc-content-intro-text">Once submitted, take a screenshot of the final page so we can verify you have completed the survey. The 20 minutes free will then be applied to your Flamingo account for your next ride.</p>
        <button className="wcc-content-intro-button" onClick={this.startSurvey}>Start Survey</button>
        <p className="wcc-content-intro-disclaimer">Only information provided in the form will be shared with Wellington City Council. No personal information will be provided from Flamingo to Wellington City Council as part of this survey. Responses must be submitted by 5pm on 14 December 2023. One submission per person. One 20 minute Flamingo credit per successful submission. $1 unlocking fee still applies when redeeming free minutes.</p>
        <img class="wcc-content-wordmark" src="https://storage.googleapis.com/flamingo-static/images/facade/wordmark-pink.svg" alt="Flamingo" />
      </div>
    );
  }

  render() {
    const { stage, loading, error } = this.state;
    return (
      <div className="wcc">
        <div className="wcc-content">
          { this.renderStage(stage, loading, error) }
        </div>
      </div>
    );
  }
}

export default WCC;

import React, { Component } from 'react';
import moment from 'moment';
import history from 'helpers/history';

const randomWidth = () => ['140px', '100px', '80px', '180px'][Math.floor(Math.random() * 3)];
const againImages = [
  'https://storage.googleapis.com/flamingo-static/images/promo/again-1.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-2.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-3.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-4.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-5.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-6.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-7.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-8.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-9.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-10.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-11.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-12.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-13.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-14.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-15.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-16.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-17.jpg',
  'https://storage.googleapis.com/flamingo-static/images/promo/again-18.jpg',
];
const shuffle = (array) => {
  let currentIndex = array.length,  randomIndex;
  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
};

class Again extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      error: false,
      clue: false,
      showClue: moment().isAfter('2024-03-22 13:00'),
    };
    this.setImages = this.setImages.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.toggleClue = this.toggleClue.bind(this);
  }

  componentDidMount() {
    document.title = 'flamingo again';
    this.setImages();
    this.loadUser();
  }

  setImages() {
    shuffle(againImages);
    const images = againImages.map((src) => ({ src, width: randomWidth() }));
    this.setState({ images });
  }

  loadUser() {
    const uuid = this.props.match.params.uuid;
    if (uuid) {
      history.push('/again');
    }
  }

  toggleClue() {
    this.setState({ clue: !this.state.clue }, this.setImages);
  }

  render() {
    const { images, clue, showClue } = this.state;

    if (showClue) {
      return (
        <div className="fm-again">
          <div className="fm-again-imgs">
            { images.map((img, i) => <img key={i} className="fm-again-img" style={{ width: img.width }} src={img.src} alt="flamingo again" />) }
          </div>
          <div className="fm-again-overlay">
            <div className="fm-again-container">
              <p className="fm-again-prize">win two tickets to fred again..</p>
              <p className="fm-again-task">Thanks for taking part, <b>the tickets have been found!</b> The secret location was Roseneath Park.<br /><br />Use the code <b>AGAINAGAIN</b> for 20 mins off your next ride with Flamingo.</p>
              <img className="fm-again-clue-img" src={ `https://storage.googleapis.com/flamingo-static/images/promo/again-clue.jpg?t=` + moment().unix() } alt="flamingo again clue" />
              <p className="fm-again-terms">Prize is for two R18 tickets to Fred Again - Friday 22 March 2024, 4:30pm, Waitangi Park, Te Aro, Wellington. Tickets are non-transferable. Prize will be awarded to the first person to arrive at the secret location on a Flamingo scooter or bike and approach the Flamingo representatives on site.</p>
            </div>
          </div>
        </div>
      );
    }

    // Main render
    const text = clue ? <>wanna go <i>again</i>? clue drop at 1pm friday. wanna go <i>again</i>? clue drop at 1pm friday. wanna go <i>again</i>? clue drop at 1pm friday. wanna go <i>again</i>? clue drop at 1pm friday. wanna go <i>again</i>? clue drop at 1pm friday. wanna go <i>again</i>? clue drop at 1pm friday. wanna go <i>again</i>? clue drop at 1pm friday.</> : <>come back <i>again</i> at 1pm friday. come back <i>again</i> at 1pm friday. come back <i>again</i> at 1pm friday. come back <i>again</i> at 1pm friday. come back <i>again</i> at 1pm friday. come back <i>again</i> at 1pm friday.</>;
    return (
      <div className="fm-again">
        <p className="fm-again-patience">find a clue for a double pass here at 1pm on friday</p>
        <div className="fm-again-imgs">
          { images.map((img, i) => <img key={i} className="fm-again-img" style={{ width: img.width }} src={img.src} alt="flamingo again" />) }
        </div>
        <div className="fm-again-ticker" onClick={this.toggleClue}>
          <p className="fm-again-text">{ text }</p>
        </div>
      </div>
    );
  }
}

export default Again;

import React, { Component } from 'react';
import api from 'helpers/api';

const levels = {
  1: { start: 70, acceptable: [{ min: 0, max: 8 }, { min: 92, max: 100 }], hint: 'Remember to park as close as possible to the side of the footpath.' },
  2: { start: 10, acceptable: [{ min: 85, max: 100 }], hint: 'Remember to use Designated Parking Areas where available.' },
  3: { start: 15, acceptable: [{ min: 90, max: 100 }], hint: 'Remember to ensure entrances and footpaths remain accessible.' },
};

class Parking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      error: false,
      loading: true,
      redeemed: false,
      card: true,
      intro: true,
      checking: false,
      passed: false,
      phoneLeft: 0,
      slider: 0,
      level: 1,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.loadParking = this.loadParking.bind(this);
    this.redeemParking = this.redeemParking.bind(this);
    this.parseParking = this.parseParking.bind(this);
    this.handleError = this.handleError.bind(this);

    this.onPlay = this.onPlay.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.onNext = this.onNext.bind(this);
    this.tryAgain = this.tryAgain.bind(this);
    this.sliderChange = this.sliderChange.bind(this);
  }

  // ----- Mounting -----

  componentDidMount() {
    document.title = 'Perfect Park | Flamingo Scooters';
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.loadParking();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  // ----- Networking -----

  loadParking() {
    const uuid = this.props.match.params.uuid;
    if (!uuid) {
      return this.setState({ error: `Invalid Session` });
    }
    return api.post('/promo/parking', { uuid })
      .then(this.parseParking)
      .catch(this.handleError);
  }

  redeemParking() {
    const { redeemed } = this.state;
    const uuid = this.props.match.params.uuid;
    if (redeemed) {
      return;
    }
    if (!uuid) {
      return this.setState({ error: `Invalid Session` });
    }
    return api.post('/promo/parking-redeem', { uuid })
      .catch(this.handleError);
  }

  parseParking(res) {
    const { redeemed } = res.data.data;
    this.setState({ loading: false, redeemed });
  }

  handleError(e) {
    const error = (window.access(() => e.response.data.code) ? e.response.data.code : e.toString()).replace('_', ' ');
    this.setState({ error, loading: false });
  }

  // ----- Handlers -----

  onPlay() {
    const level = 1;
    this.setState({ card: false, checking: false, level, slider: levels[level].start });
  }

  onCheck() {
    const { slider, level, width } = this.state;
    const currentLevel = levels[level];
    const phoneLeft = slider / 100 * width * 0.71 + width * 0.145 - width * 0.3;
    const passed = currentLevel.acceptable.some((a) => (a.min <= slider && slider <= a.max));
    this.setState({ checking: true, passed, phoneLeft: `${phoneLeft}px` });
  }

  sliderChange(e) {
    this.setState({ slider: e.target.value });
  }

  tryAgain() {
    this.setState({ checking: false });
  }

  onNext() {
    const level = this.state.level + 1;;
    if (level > 3) {
      this.setState({ checking: false, card: true, intro: false }, this.redeemParking);
    } else {
      this.setState({ checking: false, level, slider: levels[level].start });
    }
  }

  // ----- Renders -----

  render() {
    const {
      loading,
      error,
      redeemed,
      card,
      intro,
      slider,
      level,
      checking,
      passed,
      phoneLeft,
    } = this.state;
    const levelClass = `fm-parking fm-parking-${level}`;

    // Error render
    if (error) {
      return (
        <div className={levelClass}>
          <div className="fm-parking-background">
            <div className="fm-parking-error">
              <p className="fm-parking-error-title">{ error }</p>
              <p className="fm-parking-error-text">Try closing this window and tapping the banner in the Flamingo app again.</p>
            </div>
          </div>
        </div>
      );;
    }

    // Loading error
    if (loading) {
      return (
        <div className={levelClass}>
          <div className="fm-parking-background">
            <div className="fm-parking-loading">
              <div className="fm-parking-loader"></div>
            </div>
          </div>
        </div>
      );;
    }

    // Main render
    return (
      <div className={levelClass}>
        <div className="fm-parking-background">
          {
            card ? (
              <div className="fm-parking-card">
                <div className="fm-parking-card-header"></div>
                {
                  intro ? (
                    <>
                      {
                        redeemed ? (
                          <p className="fm-parking-card-text">You've <b>already redeemed your 10 minutes free</b>, but you can still practice parking.</p>
                        ) : (
                          <p className="fm-parking-card-text">Test your skills parking with Flamingo Perfect Park and get <b>10 minutes FREE</b>!</p>
                        )
                      }
                      <p className="fm-parking-card-text">Parking safely to the side of the path, or in Designated Parking Areas, helps keep our footpaths clear for everyone, including those with disabilities, prams and wheelchairs.</p>
                      <p className="fm-parking-card-subtitle">How to Play</p>
                      <div className="fm-parking-card-instruction">
                        <div className="fm-parking-card-instruction-left"></div>
                        <div className="fm-parking-card-instruction-right"></div>
                        <div className="fm-parking-card-instruction-touch"></div>
                      </div>
                      <p className="fm-parking-card-text">Drag the scooter left or right to park it safely.</p>
                      <button className="fm-parking-card-button" onClick={this.onPlay}>Play Now</button>
                    </>
                  ) : (
                    <>
                      <p className="fm-parking-card-title">Congratulations!</p>
                      {
                        redeemed ? (
                          <p className="fm-parking-card-text">You've mastered Perfect Park again.</p>
                        ) : (
                          <p className="fm-parking-card-text">You've mastered Perfect Park and earned <b>10 minutes of free</b> ride time.</p>
                        )
                      }
                      <p className="fm-parking-card-text">Did you know you can earn extra rewards with Flamingo Safety Rewards? Check out the "Safety Reward" section in the app for details.</p>
                      <p className="fm-parking-card-close">You can now close this window.</p>
                      <button className="fm-parking-card-button" onClick={this.onPlay}>Play Again</button>
                    </>
                  )
                }
              </div>
            ) : (
              <>
                <div className="fm-parking-header"></div>
                <input type="range" min="1" max="100" disabled={checking} value={slider} onChange={this.sliderChange} className="fm-parking-slider" id={`slider` + level} />
                <p className="fm-parking-instruction">Drag the scooter left or right to park it safely.</p>
                {
                  checking ? (
                    <div className="fm-parking-photo" style={{ left: phoneLeft }}>
                      <div className="fm-parking-photo-phone">
                        <div className="fm-parking-photo-phone-content">
                          {
                            passed ? (
                              <>
                                <img className="fm-parking-phone-result" src="/assets/parking/tick.svg" alt="Tick" />
                                <p className="fm-parking-phone-title">Perfect!</p>
                                <p className="fm-parking-phone-text">Parking here helps keep our footpaths clear for everyone, including those with disabilities, prams and wheelchairs.</p>
                                <img className="fm-parking-phone-image" src="/assets/parking/everyone.png" alt="Disabilities, prams and wheelchairs" />
                                <button className="fm-parking-phone-button" onClick={this.onNext}>Next</button>
                              </>
                            ) : (
                              <>
                                <img className="fm-parking-phone-result" src="/assets/parking/cross.svg" alt="Cross" />
                                <p className="fm-parking-phone-title">Not So Perfect</p>
                                <p className="fm-parking-phone-text">{ levels[level].hint }</p>
                                <p className="fm-parking-phone-text">Help keep our footpaths clear for everyone, including those with disabilities, prams and wheelchairs.</p>
                                <img className="fm-parking-phone-image" src="/assets/parking/everyone.png" alt="Disabilities, prams and wheelchairs" />
                                <button className="fm-parking-phone-button" onClick={this.tryAgain}>Try Again</button>
                              </>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  ) : (
                    <button className="fm-parking-check" onClick={this.onCheck}>Check Parking</button>
                  )
                }
              </>
            )
          }
        </div>
      </div>
    );
  }
}

export default Parking;

import React, { Component } from 'react';

class HuntRedeemed extends Component {

  componentDidMount() {
    document.title = 'Flamingo Scooters';
  }

  render() {
    return (
      <div className="hunt-redeemed">
        <div className="hunt-redeemed-box">
          <p className="hunt-redeemed-try">TRY</p>
          <p className="hunt-redeemed-again">AGAIN!</p>
        </div>
        <p className="hunt-redeemed-note">You have already scanned this poster!</p>
        <p className="hunt-redeemed-note"><b>Every poster you find and scan unlocks more FREE credit!</b></p>
        <p className="download-note">There are over 20 posters throughout Wellington and Christchurch!</p>
        <p className="hunt-redeemed-footer">Flamingo Egg Hunt 2021</p>
      </div>
    );
  }
}

export default HuntRedeemed;

import React, { Component } from 'react';
import jsQR from 'jsqr';
import api from 'helpers/api';

class CovidPass extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, loading: false };

    this.onChange = this.onChange.bind(this);
    this.qrResponse = this.qrResponse.bind(this);
    this.fileLoaded = this.fileLoaded.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Flamingo Scooters';
  }

  onChange(e) {
    if (!e.target.files[0]) return;
    this.setState({ error: false, loading: true });
    const file = e.target.files[0];

    const imageType = /^image\//;
    if (!imageType.test(file.type)) {
      return this.setState({ error: 'Please select an image.', loading: false });
    }

    const reader = new FileReader();
    reader.onloadend = this.fileLoaded;
    reader.readAsDataURL(file);
  }

  fileLoaded(e) {
    const img = new Image();
    img.onload = () => {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const width = img.width;
      const height = img.height;

      canvas.width = width;
      canvas.height = height;

      const canvasRenderingContext = canvas.getContext('2d');
      canvasRenderingContext.drawImage(img, 0, 0);
      const qrCodeImageFormat = canvasRenderingContext.getImageData(0, 0, width, height);

      const qrDecoded = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
      this.qrResponse(qrDecoded);
      canvas.remove();
    };
    img.onerror = () => this.setState({ error: 'Error parsing image, please try again.', loading: false });
    img.src = e.target.result;
  }

  qrResponse(res) {
    if (!res) {
      return this.setState({ error: 'Unable to find QR code in image.', loading: false });
    }
    if (res.data.substring(0,5) !== 'NZCP:') {
      return this.setState({ error: 'Invalid NZ My Vaccine Pass.', loading: false });
    }
    return api.post('/promo/covid-pass', { token: res.data, uuid: this.props.match.params.uuid })
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  handleResponse(res) {
    const data = res.data.data;
    if (data.valid) {
      window.location = 'https://rewards.rideflamingo.com/completed';
    } else {
      this.setState({ error: data.reason, loading: false });
    }
  }

  handleError() {
    return this.setState({ error: 'Network issue, please try again.', loading: false });
  }

  render() {
    const { loading, error } = this.state;
    return (
      <div className="covid-pass">
        <div className="covid-pass-content">
          <p className="covid-pass-title">My Vaccine Pass Verifier</p>
          <p className="covid-pass-about">Earn 500 Flamingo Safety Reward Points by verifing your My Vaccine Pass!</p>
          <p className="covid-pass-instructions">Simply take a screenshot of your My Vaccine Pass, which has the QR code clear and readable. Then select the screenshot/image with the button below to verify your pass.</p>
          { error && <p className="covid-pass-error">{ error }</p> }
          <div className="covid-pass-form">
            {
              loading ? (
                <div className="covid-pass-loading">
                  <div className="lds-heart"><div></div></div>
                  <p className="covid-pass-loading-text">Please wait...</p>
                </div>
              ) : (
                <input type="file" accept="image/*" onChange={this.onChange} />
              )
            }
          </div>
          <p className="covid-pass-disclaimer">The name on your My Vaccine Pass must match the name on your Flamingo account to be eligible. Your My Vaccine Pass data will be verified on our secure servers but will not be stored anywhere.</p>
        </div>
      </div>
    );
  }
}

export default CovidPass;

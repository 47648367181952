import React, { Component } from 'react';

class QuizIntroduction extends Component {
  constructor(props) {
    super(props);
    this.handleStart = this.handleStart.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  /* Controls */

  handleStart() {
    this.props.onStart();
  }

  /* Renders */

  renderItem(item, i) {
    const itemClass = `fm-quiz-introduction-${item.type}`;
    return <p key={i} className={itemClass} >{ item.value }</p>;
  }

  render() {
    const { quiz } = this.props;

    return (
      <div className="fm-quiz-introduction">
        <p className="fm-quiz-introduction-title">{ quiz.title }</p>
        <div className="fm-quiz-introduction-card">
          <div className="fm-quiz-introduction-photo" style={{ backgroundImage: `url('${quiz.photoUrl}')` }}></div>
          <div className="fm-quiz-introduction-items">
            { quiz.introduction.map(this.renderItem) }
          </div>
          <div className="fm-quiz-introduction-button">
            <button className="fm-quiz-introduction-start" onClick={this.handleStart}>Start Quiz</button>
          </div>
        </div>
      </div>
    );
  }
}

export default QuizIntroduction;

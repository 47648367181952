import React, { Component } from 'react';
import moment from 'moment';
import Loading from 'components/common/loading';
import history from 'helpers/history';
import api from 'helpers/api';

class Flappy extends Component {
  constructor() {
    super();

    this.state = { loading: true };

    this.started = false;
    this.startJump = false; // Has the jump started?
    this.practicing = false;
    this.playing = false;
    this.hoursToNextGame = -1;
    this.gameStartedAt = null;

    this.jumpAmount = 120; // How high is the jump?
    this.jumpTime = 266;

    this.dead = false; // is the bird dead?
    this.gap = 300
    this.masterPipeDelay = 80; // delay between pipes
    this.pipeDelay = this.masterPipeDelay; //counter used to monitor delay

    this.counterShow = false;

    this.getToken = this.getToken.bind(this);
    this.init = this.init.bind(this);
    this.onLoadComplete = this.onLoadComplete.bind(this);
    this.loadMenu = this.loadMenu.bind(this);
    this.menuButtons = this.menuButtons.bind(this);
    this.handlePractice = this.handlePractice.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handleJumpStart = this.handleJumpStart.bind(this);
    this.diveBird = this.diveBird.bind(this);
    this.die = this.die.bind(this);
    this.addClickToStart = this.addClickToStart.bind(this);
    this.addClickToRetry = this.addClickToRetry.bind(this);
    this.clearGame = this.clearGame.bind(this);
    this.goMenu = this.goMenu.bind(this);
    this.goRetry = this.goRetry.bind(this);
    this.tick = this.tick.bind(this);
    this.handleWin = this.handleWin.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Flappy Flamingo';

    let loadedScripts = 0;

    const scriptLoad = () => {
      ++loadedScripts;
      if (loadedScripts === 2) {
        this.getToken();
      }
    };

    const createScript = document.createElement('script');
    createScript.type = 'text/javascript';
    createScript.src = '/assets/flappy/createjs.min.js';

    document.getElementsByTagName('head')[0].appendChild(createScript);

    createScript.addEventListener('load', scriptLoad);

    const collisionScript = document.createElement('script');
    collisionScript.type = 'text/javascript';
    collisionScript.src = '/assets/flappy/ndgmr.Collision.js';

    document.getElementsByTagName('head')[0].appendChild(collisionScript);

    collisionScript.addEventListener('load', scriptLoad);
  }

  getToken() {
    const token = this.props.match.params.token;
    if (!token) {
      return this.setState({ loading: false, hoursToNextGame: -1 }, this.init);
    }
    this.key = Math.floor((Math.random() * 100000) + 10000)
    const key = this.key.toString(16)

    history.push('/flappy')

    api.post('/promo/flappy', { key, token })
      .then((res) => this.setState({ loading: false, hash: res.data.data.hash, hoursToNextGame: res.data.data.hoursToNextGame }, this.init))
      .catch(this.handleError);
  }

  init() {
    this.hoursToNextGame = this.state.hoursToNextGame;

    this.stage = new window.createjs.Stage('flappyFlamingo');

    window.createjs.Touch.enable(this.stage);

    this.w = this.stage.canvas.width;
    this.h = this.stage.canvas.height;

    this.manifest = [
      { src: '/assets/flappy/bird.png', id: 'bird' },
      { src: '/assets/flappy/background.png', id: 'background' },
      { src: '/assets/flappy/ground.png', id: 'ground' },
      { src: '/assets/flappy/logo.png', id: 'logo' },
      { src: '/assets/flappy/loose.png', id: 'loose' },
      { src: '/assets/flappy/menu.png', id: 'menu' },
      { src: '/assets/flappy/practice.png', id: 'practice' },
      { src: '/assets/flappy/pipe.png', id: 'pipe' },
      { src: '/assets/flappy/play.png', id: 'play' },
      { src: '/assets/flappy/play-disabled.png', id: 'play-disabled' },
      { src: '/assets/flappy/retry.png', id: 'retry' },
      { src: '/assets/flappy/win.png', id: 'win' },
      { src: '/assets/flappy/FB.eot' },
      { src: '/assets/flappy/FB.ttf' },
      { src: '/assets/flappy/FB.woff' }
    ];

    this.loader = new window.createjs.LoadQueue(false);
    this.loader.addEventListener('complete', this.onLoadComplete);
    this.loader.loadManifest(this.manifest);
  }

  onLoadComplete() {
    this.background = new window.createjs.Shape();
    this.background.graphics.beginBitmapFill(this.loader.getResult('background')).drawRect(0, 0, this.w, this.h);

    const groundImg = this.loader.getResult('ground');
    this.ground = new window.createjs.Shape();
    this.ground.graphics.beginBitmapFill(groundImg).drawRect(0, 0, this.w + groundImg.width, groundImg.height);
    this.ground.tileW = groundImg.width;
    this.ground.y = this.h - groundImg.height;


    const data = new window.createjs.SpriteSheet({
      'images': [this.loader.getResult('bird')],
      'frames': {
        'width': 92,
        'height': 80,
        'regX': 46,
        'regY': 40,
        'count': 3
      },
      'animations': {
        'fly': [0, 2, 'fly', 0.21],
        'dive': [1, 1, 'dive', 1]
      }
    });
    this.bird = new window.createjs.Sprite(data, 'fly');

    this.startX = (this.w / 2) - (92 / 2)
    this.startY = 512
    this.wiggleDelta = 18

    this.bird.setTransform(this.startX, this.startY, 1, 1);
    this.bird.framerate = 30;

    window.createjs.Tween.get(this.bird, {
      loop: true
    }).to({
      y: this.startY + this.wiggleDelta
    }, 380, window.createjs.Ease.sineInOut).to({
      y: this.startY
    }, 380, window.createjs.Ease.sineInOut);

    this.stage.addChild(this.background);

    this.pipes = new window.createjs.Container();
    this.stage.addChild(this.pipes)

    this.stage.addChild(this.bird, this.ground);
    this.stage.addEventListener('stagemousedown', this.handleJumpStart);

    this.counter = new window.createjs.Text(0, '86px \'Flappy Bird\'', '#ffffff');
    this.counterOutline = new window.createjs.Text(0, '86px \'Flappy Bird\'', '#000000');
    this.counterOutline.outline = 5
    this.counterOutline.textAlign = 'center'
    this.counter.textAlign = 'center'
    this.counterOutline.x = this.w / 2
    this.counterOutline.y = 150
    this.counter.x = this.w / 2
    this.counter.y = 150
    this.counter.alpha = 0
    this.counterOutline.alpha = 0
    this.stage.addChild(this.counter, this.counterOutline)

    window.createjs.Ticker.timingMode = window.createjs.Ticker.RAF;
    window.createjs.Ticker.addEventListener('tick', this.tick);

    this.loadMenu()
  }

  loadMenu() {
    this.logo = new window.createjs.Bitmap(this.loader.getResult('logo'));
    this.logo.alpha = 1
    this.logo.x = this.w / 2 - this.logo.image.width / 2
    this.logo.y = 100
    this.stage.addChild(this.logo)

    this.smallPrint = new window.createjs.Text().set({
      text: '1 minute for each point scored. One game every 24 hours.',
      x: this.w / 2,
      y: this.h - 50,
      font: '20px Arial',
      color: '#A35628',
      lineWidth: this.w - 100,
      textAlign: 'center',
      textBaseline: 'middle',
    });
    this.stage.addChild(this.smallPrint)

    this.menuButtons()
  }

  menuButtons() {
    const message = this.hoursToNextGame < 0 ? `Come back later to play again!` : (this.hoursToNextGame === 0 ? 'Play now to win free minutes!' : `Come back in ${this.hoursToNextGame} hours to play!`);
    this.description = new window.createjs.Text().set({
      text: message,
      x: this.w / 2,
      y: 340,
      font: '35px \'Flappy Bird\'',
      color: '#fff',
      lineWidth: this.w - 100,
      textAlign: 'center',
      textBaseline: 'middle',
    });
    this.stage.addChild(this.description)

    this.play = new window.createjs.Bitmap(this.loader.getResult(this.hoursToNextGame === 0 ? 'play' : 'play-disabled'));
    this.play.alpha = 1
    this.play.x = this.w / 2 - this.play.image.width - 20
    this.play.y = 620
    this.stage.addChild(this.play)

    this.practice = new window.createjs.Bitmap(this.loader.getResult('practice'));
    this.practice.alpha = 1
    this.practice.x = this.w / 2 + 20
    this.practice.y = 620
    this.stage.addChild(this.practice)

    if (this.hoursToNextGame === 0) {
      this.play.addEventListener('click', this.handlePlay);
    }
    this.practice.addEventListener('click', this.handlePractice);
  }

  handlePractice() {
    this.practicing = true
    this.handlePlay()
  }

  handlePlay() {
    this.playing = true
    this.stage.removeChild(this.description)
    this.stage.removeChild(this.play)
    this.stage.removeChild(this.practice)
    this.logo.alpha = 0
    this.smallPrint.text = 'Tap above the pink line.'
    this.counter.alpha = 1
    this.counterOutline.alpha = 1
    this.counterShow = true
  }

  handleJumpStart() {
    if (!this.dead && this.playing) {
      window.createjs.Tween.removeTweens(this.bird)
      this.bird.gotoAndPlay('jump');
      this.startJump = true
      if (!this.started) {
        this.started = true
        this.counterShow = true
        this.logo.alpha = 0
        this.gameStartedAt = moment()
      }
    }
  }

  diveBird() {
    this.bird.gotoAndPlay('dive');
  }

  die() {
    this.dead = true
    this.playing = false
    this.bird.gotoAndPlay('dive');
    window.createjs.Tween.removeTweens(this.bird)
    window.createjs.Tween.get(this.bird).wait(0).to({
        y: this.bird.y + 200,
        rotation: 90
      }, (380) / 1.5, window.createjs.Ease.linear)
      .call(this.diveBird)
      .to({
        y: this.ground.y - 30
      }, (this.h - (this.bird.y + 200)) / 1.5, window.createjs.Ease.linear);
    window.createjs.Tween.get(this.stage).to({
      alpha: 0
    }, 100).to({
      alpha: 1
    }, 100)

    if (this.practicing) {
      this.retry = new window.createjs.Bitmap(this.loader.getResult('retry'));
      this.retry.alpha = 0
      this.retry.x = this.w / 2 - this.retry.image.width / 2
      this.retry.y = 350

      this.menu = new window.createjs.Bitmap(this.loader.getResult('menu'));
      this.menu.alpha = 0
      this.menu.x = this.w / 2 - this.menu.image.width / 2
      this.menu.y = 450

      this.stage.addChild(this.retry)
      this.stage.addChild(this.menu)

      window.createjs.Tween.get(this.retry).to({
        alpha: 1,
        y: this.retry.y + 50
      }, 400, window.createjs.Ease.sineIn).call(this.addClickToRetry)
      window.createjs.Tween.get(this.menu).to({
        alpha: 1,
        y: this.menu.y + 50
      }, 400, window.createjs.Ease.sineIn).call(this.addClickToStart)

    } else {

      const score = this.counter.text;
      if (score > 0) {
        this.handleWin(score)
      }

      this.hoursToNextGame = 24

      this.menu = new window.createjs.Bitmap(this.loader.getResult('menu'));
      this.menu.alpha = 0
      this.menu.x = this.w / 2 - this.menu.image.width / 2
      this.menu.y = 570

      this.win = new window.createjs.Bitmap(this.loader.getResult(score === 0 ? 'loose' : 'win'));
      this.win.alpha = 0
      this.win.x = this.w / 2 - this.win.image.width / 2
      this.win.y = 230

      this.stage.addChild(this.menu)
      this.stage.addChild(this.win)

      window.createjs.Tween.get(this.menu).to({
        alpha: 1,
        y: this.menu.y + 50
      }, 400, window.createjs.Ease.sineIn).call(this.addClickToStart)
      window.createjs.Tween.get(this.win).to({
        alpha: 1,
        y: this.win.y + 50
      }, 400, window.createjs.Ease.sineIn)
    }
  }

  addClickToStart() {
    this.menu.addEventListener('click', this.goMenu);
  }

  addClickToRetry() {
    this.retry.addEventListener('click', this.goRetry);
  }

  clearGame() {
    this.pipes.removeAllChildren()

    this.stage.removeChild(this.win)
    this.stage.removeChild(this.menu)
    this.stage.removeChild(this.retry)

    this.counter.text = 0
    this.counterOutline.text = 0
    this.pipeDelay = this.masterPipeDelay
    this.dead = false
    this.started = false
    this.startJump = false
    window.createjs.Tween.removeTweens(this.bird)
    this.bird.x = this.startX
    this.bird.y = this.startY
    this.bird.rotation = 0
    window.createjs.Tween.get(this.bird, {
      loop: true
    }).to({
      y: this.startY + this.wiggleDelta
    }, 380, window.createjs.Ease.sineInOut).to({
      y: this.startY
    }, 380, window.createjs.Ease.sineInOut);

  }

  goRetry() {
    this.clearGame()

    this.playing = true
    this.practicing = true
  }

  goMenu() {
    this.clearGame()

    this.playing = false
    this.practicing = false
    this.logo.alpha = 1
    this.smallPrint.text = '1 minute for each point scored. One game every 24 hours.'
    this.counterOutline.alpha = 0
    this.counter.alpha = 0
    this.counterShow = false

    this.menuButtons()
  }

  tick(event) {
    var deltaS = event.delta / 1000;

    var l = this.pipes.getNumChildren();

    if (this.bird.y > (this.ground.y - 40)) {
      if (!this.dead) {
        this.die()
      }
      if (this.bird.y > (this.ground.y - 30)) {
        window.createjs.Tween.removeTweens(this.bird)
      }
    }

    if (!this.dead) {
      this.ground.x = (this.ground.x - deltaS * 300) % this.ground.tileW;
    }

    if (this.started && !this.dead) {
      if (this.pipeDelay < 0) {

        let gap = this.gap - this.counter.text

        this.pipe = new window.createjs.Bitmap(this.loader.getResult('pipe'));
        this.pipe.x = this.w + 600
        this.pipe.y = (this.ground.y - gap * 2) * Math.random() + gap * 1.5
        this.pipes.addChild(this.pipe);

        this.pipe2 = new window.createjs.Bitmap(this.loader.getResult('pipe'));
        this.pipe2.scaleX = -1
        this.pipe2.rotation = 180
        this.pipe2.x = this.pipe.x
        this.pipe2.y = this.pipe.y - gap
        this.pipes.addChild(this.pipe2);

        this.pipeDelay = this.masterPipeDelay

      } else {
        this.pipeDelay = this.pipeDelay - deltaS * 66
      }
      for (var i = 0; i < l; i++) {
        this.pipe = this.pipes.getChildAt(i);
        if (this.pipe) {
          var collision = window.ndgmr.checkRectCollision(this.pipe, this.bird, 1, true)
          if (collision) {
            if (collision.width > 8 && collision.height > 8) {
              this.die()
            }
          }
          this.pipe.x = (this.pipe.x - deltaS * 300);
          if (this.pipe.x <= 338 && this.pipe.rotation === 0 && this.pipe.name !== 'counted') {
            this.pipe.name = 'counted'
            this.counter.text = this.counter.text + 1
            this.counterOutline.text = this.counterOutline.text + 1
          }
          if (this.pipe.x + this.pipe.image.width <= -this.pipe.w) {
            this.pipes.removeChild(this.pipe)
          }
        }
      }
      if (this.counterShow) {
        this.counter.alpha = 1
        this.counterOutline.alpha = 1
        this.counterShow = false
      }
    }

    if (this.startJump === true) {
      this.startJump = false
      this.bird.framerate = 60;
      this.bird.gotoAndPlay('fly');
      if (this.bird.roation < 0) {
        this.rotationDelta = (-this.bird.rotation - 20) / 5
      } else {
        this.rotationDelta = (this.bird.rotation + 20) / 5
      }
      if (this.bird.y < -200) {
        this.bird.y = -200
      }
      window.createjs
        .Tween
        .get(this.bird)
        .to({
          y: this.bird.y - this.rotationDelta,
          rotation: -20
        }, this.rotationDelta, window.createjs.Ease.linear)
        .to({
          y: this.bird.y - this.jumpAmount,
          rotation: -20
        }, this.jumpTime - this.rotationDelta, window.createjs.Ease.quadOut)
        .to({
          y: this.bird.y
        }, this.jumpTime, window.createjs.Ease.quadIn)
        .to({
          y: this.bird.y + 200,
          rotation: 90
        }, (380) / 1.5, window.createjs.Ease.linear)
        .call(this.diveBird)
        .to({
          y: this.ground.y - 30
        }, (this.h - (this.bird.y + 200)) / 1.5, window.createjs.Ease.linear);
    }

    this.stage.update(event);
  }

  handleWin(score) {
    const { hash } = this.state;
    const game = (moment().diff(this.gameStartedAt) * score * this.key).toString(16);
    api.post('/promo/flappy/win', { score, game, hash })
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    alert(error);
    window.location = 'https://promo.flamingoscooters.com/flappy/';
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <div className="fm-flappy-loading">
          <Loading />
        </div>
      );;
    }

    return (
      <div className="fm-flappy">
        <div className="fm-flappy-background-loading">
          <Loading />
        </div>
        <canvas id="flappyFlamingo" width="768" height="1024"></canvas>
      </div>
    );
  }
}

export default Flappy;

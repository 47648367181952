import React, { Component } from 'react';

class QuizProgress extends Component {
  render() {
    const { questions, currentQuestion } = this.props;
    return (
      <div className="fm-quiz-progress">
        <p className="fm-quiz-progress-label">Question { currentQuestion + 1 } / { questions }</p>
        <div className="fm-quiz-progress-bar">
          <div className="fm-quiz-progress-bar-inner" style={{ width: `${(currentQuestion + 1) / questions * 100}%` }}></div>
        </div>
      </div>
    );
  }
}

export default QuizProgress;

import React, { Component } from 'react';
import Confetti from 'react-confetti'
import moment from 'moment'

class HuntWin extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    document.title = 'Flamingo Scooters';
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const isMinutes = this.props.match.params.type === 'm';
    return (
      <div className="hunt-win">
        <div className="hunt-win-box">
          {
            isMinutes ? (
              <>
                <p className="hunt-win-value">{ this.props.match.params.value }</p>
                <p className="hunt-win-free">MINUTES FREE!</p>
              </>
            ) : (
              <>
                <p className="hunt-win-value">${ parseInt(this.props.match.params.value / 100) }</p>
                <p className="hunt-win-free">FREE!</p>
              </>
            )
          }
        </div>
        {
          isMinutes ? (
            <p className="hunt-win-note">Congratulations, you've found { this.props.match.params.value } minutes of FREE credit!</p>
          ) : (
            <p className="hunt-win-note">Congratulations, you've found ${ parseInt(this.props.match.params.value / 100) } of FREE credit!</p>
          )
        }
        <p className="hunt-win-note">This credit have been automatically applied to your account. You can view your remaining credit from the payment screen.</p>
        <p className="hunt-win-note"><b>Happy Easter!</b></p>
        <Confetti width={this.state.width} height={this.state.height} numberOfPieces={75} colors={['#d81759', '#ff4887']} confettiSource={{ x: 0, y: 0, h: 0, w: this.state.width }} />
        <p className="hunt-win-footer">Flamingo Egg Hunt { moment().format('YYYY') }</p>
      </div>
    );
  }
}

export default HuntWin;

import React, { Component } from 'react';

class HuntNoLocation extends Component {

  componentDidMount() {
    document.title = 'Flamingo Scooters';
  }

  render() {
    return (
      <div className="hunt-redeemed">
        <div className="hunt-redeemed-box">
          <p className="hunt-redeemed-try">TRY</p>
          <p className="hunt-redeemed-again">AGAIN!</p>
        </div>
        <p className="hunt-redeemed-note">Make sure Location Services are on and your app is up to date!</p>
        <p className="hunt-redeemed-footer">Flamingo Egg Hunt 2021</p>
      </div>
    );
  }
}

export default HuntNoLocation;

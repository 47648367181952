import React, { Component } from 'react';

class HuntTooMany extends Component {

  componentDidMount() {
    document.title = 'Flamingo Scooters';
  }

  render() {
    return (
      <div className="hunt-redeemed">
        <div className="hunt-redeemed-box">
          <p className="hunt-redeemed-try">TRY</p>
          <p className="hunt-redeemed-again">AGAIN!</p>
        </div>
        <p className="hunt-redeemed-note">You've just scanned a poster! Check the speed of your scooter and try again.</p>
        <p className="download-note">(Remember you must scan the poster in person).</p>
        <p className="hunt-redeemed-footer">Flamingo Egg Hunt 2021</p>
      </div>
    );
  }
}

export default HuntTooMany;

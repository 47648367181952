import React, { Component } from 'react';
import history from 'helpers/history';
import config from './config';

import QuizCard from './components/quiz-card';
import QuizIntroduction from './components/quiz-introduction';
import QuizProgress from './components/quiz-progress';

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = { hasStarted: false, hasFinished: false, currentQuestion: 0, quiz: undefined };

    this.loadQuiz = this.loadQuiz.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.quiz && config.quizzes[this.props.match.params.quiz]) {
      return this.loadQuiz(config.quizzes[this.props.match.params.quiz]);
    }
    history.push('/');
  }

  loadQuiz(quiz) {
    document.title = quiz.title;
    this.setState({ quiz });
  }

  handleStart() {
    this.setState({ hasStarted: true });
  }

  handleNext() {
    this.setState({ currentQuestion: this.state.currentQuestion + 1 });
  }

  render() {
    const { quiz, currentQuestion, hasStarted } = this.state;
    return (
      <div className="fm-quiz">
        { (quiz && !hasStarted) && <QuizIntroduction quiz={quiz} onStart={this.handleStart} /> }
        { (quiz && hasStarted) && <QuizProgress currentQuestion={currentQuestion} questions={quiz.questions.length} /> }
        { (quiz && hasStarted) && <QuizCard question={quiz.questions[currentQuestion]} onNext={this.handleNext} /> }
      </div>
    );
  }
}

export default Quiz;

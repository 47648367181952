import React, { Component } from 'react';
import moment from 'moment';
import Confetti from 'react-confetti'
import api from 'helpers/api';
import history from 'helpers/history';

const prizeTitles = [
  'Wicked!',
  'Enjoy the Ride!',
  'Woooo!',
  'Happy Riding!',
  'Flamazing!',
  'Keep the wheels spinning!',
];

class Bike extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      error: false,
      loading: true,
      spinning: false,
      finished:  false,
      prize: {},
      lastDay: parseInt(moment().format('D')) === 31,
      prizeTitle: prizeTitles[Math.floor(Math.random() * prizeTitles.length)],
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.loadPrize = this.loadPrize.bind(this);
    this.parsePrize = this.parsePrize.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSpin = this.handleSpin.bind(this);
    this.handleWin = this.handleWin.bind(this);
    this.handleDone = this.handleDone.bind(this);
  }

  // ----- Mounting -----

  componentDidMount() {
    document.title = 'Summer Cycles | Flamingo Scooters';
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.loadPrize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  // ----- Networking -----

  loadPrize() {
    const uuid = this.props.match.params.uuid;
    if (!uuid) {
      return this.setState({ loading: false, error: `You are not logged in.` });
    }

    history.push('/bike')
    return api.post('/promo/bike', { uuid })
      .then(this.parsePrize)
      .catch(this.handleError);
  }

  parsePrize(res) {
    const prizeData = res.data.data;
    const prizeMap = {
      'MINUTES5': { position: 'm5', label: '5 minutes of FREE credit' },
      'MINUTES10': { position: 'm10', label: '10 minutes of FREE credit' },
      'MINUTES15': { position: 'm15', label: '15 minutes of FREE credit' },
      'MINUTES20': { position: 'm20', label: '20 minutes of FREE credit' },
      'MINUTES100': { position: 'm100', label: '100 minutes of FREE credit' },
      'CREDIT500': { position: 'c5', label: '$5 of FREE credit' },
      'CREDIT1000': { position: 'c10', label: '$10 of FREE credit' },
      'CREDIT2000': { position: 'c20', label: '$20 of FREE credit' },
    };
    const prizeKey = prizeData.type + prizeData.value;
    const prize = prizeMap[prizeKey];
    prize.redeemed = prizeData.redeemed;
    this.setState({ loading: false, prize });
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : e.toString();
    this.setState({ error, loading: false });
  }

  // ----- Handlers -----

  handleSpin() {
    const { prize, spinning } = this.state;
    if (!spinning && !prize.redeemed) {
      this.setState({ spinning: true });
      setTimeout(this.handleWin, 9000);
    }
  }

  handleWin() {
    this.setState({ confetti: true, finished: true });
    setTimeout(this.handleDone, 10000);
  }

  handleDone() {
    this.setState({ confetti: false });
  }

  // ----- Renders -----

  render() {
    const { loading, error, width, height, spinning, finished, prize, lastDay } = this.state;
    const spinnerClass = `fm-bike-wheel-spinner` + (spinning || prize.redeemed ? ` fm-bike-wheel-spinner-${prize.position}` : ``);

    // Error render
    if (error) {
      return (
        <div className="fm-bike">
          <div className="fm-bike-error">
            <p className="fm-bike-error-title">{ error }</p>
            <p className="fm-bike-error-text">Try closing this window and tapping the banner in the Flamingo app again.</p>
          </div>
        </div>
      );;
    }

    // Loading error
    if (loading) {
      return (
        <div className="fm-bike">
          <div className="fm-bike-loading">
            <div className="fm-bike-loading-spinner"></div>
          </div>
        </div>
      );;
    }

    // Main render
    return (
      <div className="fm-bike">
        <div className="fm-bike-header">
          <img className="fm-bike-header-wordmark" alt="Flamingo" src="/assets/images/wordmark.svg" />
          <img className="fm-bike-header-title" alt="Summer Cycles" src="https://storage.googleapis.com/flamingo-static/images/promo/bike-summer-cycles.png" />
          <img className="fm-bike-header-background" alt="Flamingo Bike" src="https://storage.googleapis.com/flamingo-static/images/promo/bike-splash.jpg" />
        </div>
        <div className="fm-bike-content">
          <p className="fm-bike-instructions">To help you get around this summer we're giving you <b>FREE credit</b> on Flamingo <b>electric bikes each day</b>.</p>
          <p className="fm-bike-instructions">Spin the wheel below to unlock your prize!</p>
          <div className="fm-bike-wheel" onClick={this.handleSpin}>
            <div className={spinnerClass}></div>
            <div className="fm-bike-wheel-frame"></div>
          </div>
          <div className="fm-bike-controls">
            {
              finished ? (
                <>
                  <p className="fm-bike-controls-won"><b>Congratulations!</b> You have won { prize.label } for Flamingo electric bikes.</p>
                  <p className="fm-bike-controls-won">This has been applied to your Flamingo account. This credit is <b>valid for 24 hours</b>, so make sure to get in quick.</p>
                  {
                    lastDay ? (
                      <p className="fm-bike-instructions">Thanks for riding with us this summer!</p>
                    ) : (
                      <p className="fm-bike-instructions"><b>Remember to come back tomorrow for more!</b></p>
                    )
                  }
                </>
              ) : (
                !spinning && prize.redeemed ? (
                  <>
                  <p className="fm-bike-controls-won"><b>You've already spun the wheel today!</b> You won { prize.label } for Flamingo <b>electric bikes</b>.</p>
                  <p className="fm-bike-controls-won">This has been applied to your Flamingo account. This credit is <b>valid for 24 hours</b>, so make sure to get in quick.</p>
                    {
                      lastDay ? (
                        <p className="fm-bike-instructions">Thanks for riding with us this summer!</p>
                      ) : (
                        <p className="fm-bike-instructions"><b>Remember to come back tomorrow for more!</b></p>
                      )
                    }
                  </>
                ) : (
                  <button className="fm-bike-button" onClick={this.handleSpin}>SPIN</button>
                )
              )
            }
          </div>
        </div>
        <p className="fm-bike-terms">1 prize per person each calendar day from 1 January 2023 through to 31 January 2023. $1 unlocking fee still applies. Vouchers are valid for 24 hours. Prizes may take up to 10 minutes to be applied to your account. Flamingo reserves the right to remove prizes from accounts that break these conditions.</p>
        { finished && <div className="fm-bike-confetti"><Confetti width={width} height={height} numberOfPieces={50} colors={['#d81759', '#ff4887']} confettiSource={{ x: 0, y: 0, h: 0, w: width }} /></div> }
      </div>
    );
  }
}

export default Bike;
